import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BaseSection from "../components/base-section"
import BaseHeader from "../components/base-header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseAccordionGroup from "../components/base-accordion-group"

const FAQPage = () => {
  const { strapiFaq } = useStaticQuery(graphql`
    query {
      strapiFaq {
        title
        cover {
          url
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        accordionSection {
          id
          title
          nested {
            id
            title
            body {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        cover {
          id
        }
      }
    }
  `)
  const { title, cover, accordionSection, defaultSeo } = strapiFaq

  return (
    <Layout>
      <Seo seo={defaultSeo} />
      <BaseHeader
        title={title}
        image={
          <GatsbyImage
            className="rounded-md"
            placeholder="blurred"
            alt={cover?.alternativeText || ""}
            layout="fullWidth"
            image={getImage(cover?.localFile)}
          />
        }
      />
      <BaseSection>
        <BaseAccordionGroup data={accordionSection} />
      </BaseSection>
    </Layout>
  )
}

export default FAQPage
